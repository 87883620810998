// Core
import React, { useState } from 'react';
// Styles
import './styles.scss';

import * as L from 'leaflet';
import { useEffect } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Fade } from "react-reveal";
import BooksByLocationModal from 'components/modals/BooksByLocationModal';


const BookMap = () => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { t } = useTranslation();
  const [location, setLocation] = useState({})
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMap();
  },[]);

  const fetchMap = async () => {
    const res = await fetchData(restAPIs.awardsByLocation(currentLang?.id));
    const map = L.map("map").setView([15.85778091911655, 52.412109375000004], 4);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '...'
    }).addTo(map);
    map.on('click', function (e) { console.log('map click!', e) });
    for (let i = 0; i < res.locations.length; i++) {
      const element = res.locations[i];
      let marker = L.marker([element.latitude, element.longitude], { opacity: 1, fillOpacity: 0.9, fillColor: '#ffc107' });
      marker.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
        if (e) {
          dispatch({
            type: 'locationByBook',
            value: res.locations[i]
          });
          setLocation(res.locations[i])
          setOpen(true)
        }
      });
      marker.addTo(map);
    }
  }

  const handleClose = () => {
    setOpen(false)
    dispatch({
      type: 'locationByBook',
      value: {}
    });
  }


  return (


    <>
      <div className='container-fluid __articles_list' style={{ paddingTop: '12vh', paddingBottom: '6%' }}>
        <div className='inner'>
          <div className="c-heading text-center">

            <div className="c-heading__middle">
              <h3 className="heading-3 text-uppercase">{t('map')}</h3>
            </div>
          </div>
        </div>
        <div className="block __articles">
          <Fade bottom duration={800} delay={500}>
            <div className="fs-container">
              <div className="map-container" id="map" style={{ zIndex: 0 }}></div>
            </div>

          </Fade>
        </div>
      </div>
      <BooksByLocationModal location={location} open={open} onClose={handleClose} />

    </>
  );
};

export default BookMap;
