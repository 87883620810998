import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "antd";
import { AudioOutlined, CloseOutlined, StopOutlined, PlayCircleOutlined, UserOutlined, LinkOutlined } from "@ant-design/icons";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import EvLogo from "assets/images/chat/evLogo.png";
import Bot from "assets/images/chat/chat_bot.gif";
import Loader from "assets/images/chat/loader.gif";
import Speak from "assets/images/chat/speak.png";
import Speaking from "assets/images/chat/speaking.png";
import Excerpt from "components/modals/Excerpt";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const responsiveVoice = window.responsiveVoice;

const AskModal = ({ onClose, open }) => {
    const containerBody = useRef(null);
    const [isInitial, setIsInitial] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState("");
    const [messageList, setMessageList] = useState([]);
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isContextOpen, setIsContextOpen] = useState(false);
    const [context, setContext] = useState();
    const navigate = useNavigate()

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.error("Browser does not support speech recognition.");
            return;
        }
        if (listening) {
            setText(transcript);
        }
    }, [listening, transcript, browserSupportsSpeechRecognition]);

    useEffect(() => {
        scrollToBottom();
    }, [messageList, isLoading]);

    const scrollToBottom = () => {
        if (containerBody.current) {
            containerBody.current.scrollTop = containerBody.current.scrollHeight;
        }
    };

    const submitText = async () => {
        if (!text.trim()) return;
        setIsInitial(true);
        setIsLoading(true);

        const userMessage = { type: "user", text };
        setMessageList((prev) => [...prev, userMessage]);

        resetTranscript();
        const temp = text;
        setText("");

        try {
            const response = await fetch(
                `https://evai.in/articles/answer?query=${encodeURIComponent(temp)}`
            );
            const res = await response.json();

            // Ensure answer_list is always an array
            let answers = []
            if (res.answer_list.length > 0) {
                answers = res.answer_list
            } else {
                answers[0] = res.answer
            }
            // Construct HTML content for all answers
            const formattedAnswers = answers
                .map(
                    (answer) => `
                    <p>${answer.answer}</p>
                    <span><a class="__share" href=https://www.electronicvillage.org/article/${answer.article_id} target="_blank" rel="noopener noreferrer">View Article</a></span>
                `
                )
                .join("");

            // Create AI message with HTML content
            const aiMessage = {
                type: "response",
                text: formattedAnswers,
                plaintext: answers.map((a) => a.answer).join("\n\n"),
                isHTML: true, // This flag helps when rendering
                context: res.context,
            };

            setMessageList((prev) => [...prev, aiMessage]);

            // Simulate typing effect
            simulateTyping(answers.map((a) => a.answer).join("\n\n"), () => setIsLoading(false));
            setIsLoading(false)
            // Start speaking the first answer (optional)
            if (answers.length > 0) handleSpeak(answers.map((a) => a.answer).join("\n\n"));
        } catch (error) {
            console.error("Error fetching response:", error);
            setIsLoading(false);
        }
    };


    const handleShareAnswer = (answer) => {
        navigator.share
            ? navigator.share({ title: "EV.Ai Answer", text: answer }).catch(console.error)
            : alert("Sharing is not supported in your browser.");
    };

    const handleStartListening = () => SpeechRecognition.startListening({ continuous: true });

    const handleStopListening = () => {
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleClose = () => {
        setIsInitial(false);
        setIsLoading(false);
        setText("");
        setMessageList([]);
        handleCancel()
        onClose();
    };

    const simulateTyping = (messages, callback) => {
        let index = 0;

        const typeNext = () => {
            if (index >= messages.length) {
                callback?.();
                return;
            }

            let currentText = "";
            const fullText = messages[index].text;
            const interval = setInterval(() => {
                if (currentText?.length < fullText?.length) {
                    currentText = fullText.slice(0, currentText.length + 1);
                    setMessageList((prev) =>
                        prev.map((msg, i) =>
                            i === prev.length - (messages.length - index)
                                ? { ...msg, text: currentText }
                                : msg
                        )
                    );
                } else {
                    clearInterval(interval);
                    index++;
                    typeNext();
                }
            }, 30);
        };

        typeNext();
    };


    const handleSpeak = (text) => {
        setIsPlaying(true);
        responsiveVoice.speak(text, "Arabic Male", {
            onstart: () => setIsPlaying(true),
            onend: () => setIsPlaying(false),
            onerror: () => setIsPlaying(false),
        });
    };

    const handleCancel = () => {
        responsiveVoice.cancel();
        setIsPlaying(false);
    };

    const handleExcerpt = (context) => {
        setContext(context);
        setIsContextOpen(true);
    };


    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                footer={null}
                width={800}
                centered
            >
                <div className="___chat_container_wrapper">
                    <div className="__chat_display" ref={containerBody}>
                        {isInitial ? (

                            messageList.map((item, index) => (
                                <div
                                    key={index}
                                    className={item.type === "user" ? "___use_resp_set user" : "___use_resp_set"}
                                    style={
                                        item.type === "user"
                                            ? { justifyContent: "end", flexDirection: "row-reverse", textAlign: "right" }
                                            : {}
                                    }
                                >
                                    <div className="___user">
                                        {item.type === "user" ? (
                                            <UserOutlined style={{ fontSize: "22px" }} />
                                        ) : (
                                            <img src={EvLogo} alt="EV Logo" />
                                        )}
                                    </div>
                                    <div className="___response">
                                        <h2>{item.type === "user" ? "You" : "EV.Ai"}</h2>

                                        {item.isHTML ? (
                                            <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                        ) : (
                                            <p>{item.text}</p>
                                        )}

                                        {item.type === "response" && (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "20px",
                                                        alignItems: "center",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    {item.article_id && (
                                                        <a
                                                            className="__share"
                                                            href={`https://www.electronicvillage.org/article/${item.article_id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Article
                                                        </a>
                                                    )}
                                                    {
                                                        <button className="__share" onClick={() => handleShareAnswer(item.plaintext)}>
                                                            🔗 Share
                                                        </button>
                                                    }
                                                    {isPlaying ? (
                                                        <button className="__share" onClick={handleCancel}>
                                                            <StopOutlined />
                                                        </button>
                                                    ) : (
                                                        <button className="__share" onClick={() => handleSpeak(item.plaintext)}>
                                                            <PlayCircleOutlined />
                                                        </button>
                                                    )}
                                                    <button className="__share" onClick={() => handleExcerpt(item.context)}>
                                                        Knowledge Excerpt
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="__initial_loader">
                                <img src={Bot} alt="Bot" />
                                <span>
                                    Ask anything about <b>{'Articles'}</b>
                                </span>
                            </div>
                        )}
                        {isLoading && (
                            <div className="__loading">
                                <img src={Loader} alt="Loading" />
                                Generating Answer...
                            </div>
                        )}
                    </div>
                    <div className="___send_form">
                        <textarea
                            className="___text_area"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            placeholder="Ask me anything!"
                            onKeyDown={(e) => e.key === "Enter" && submitText()}
                        />
                        <div className="__right_btn_actions">
                            {!listening ? (
                                <button onClick={handleStartListening} className="__micr">
                                    <img src={Speak} alt="Start Listening" />
                                </button>
                            ) : (
                                <button onClick={handleStopListening} className="__micr">
                                    <img src={Speaking} alt="Stop Listening" />
                                </button>
                            )}
                            <Button onClick={submitText} disabled={!text.trim()}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#fff"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z" /></svg>Ask</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Excerpt open={isContextOpen} onClose={() => setIsContextOpen(false)} context={context} />
        </>
    );
};

export default AskModal;
